.form-container {
  margin: auto;
  text-align: center;
  width: 50%;
}

.login-container {
  margin: auto;
  width: 50%;
}

.form-wrapper {
  text-align: left;
  width: 50%;
  margin: auto;
}

.form-wrapper form .form-group input {
  width: 100%;
  padding: 23px 16px;
  font-size: 18px;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  display: inline-block;
  height: 20px;
}

.form-wrapper form .form-group input:focus {
  border: 1px solid black;
}

.form-wrapper form button.login-button {
  width: 100%;
  padding: 12px 20px;
  background: #4eb478;
  font-size: 18px;
  border: none;
  font-weight: bold;
  /* border-radius: 11px; */
  color: white;
}

.alert.alert-success .alertmessage{
  margin: 14px 0;
  font-size: 16px;
}

.form-wrapper form button.facebook-login {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  opacity: 0.85;
  margin: 15px 0;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}

.google {
  background: #dd4b39;
  color: white;
}

.fb {
  background: #3b5998;
  color: white;
}

.addressitem {
  display: inline-block;
  padding: 0 10px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    /* Styles */
    .form-container {
        width: 100%;
      }
      .form-wrapper {
        width: 100%;
    }

    }
    
    /* Smartphones (landscape) ----------- */
    @media only screen and (min-width : 321px) {
    /* Styles */
    }
    
    /* Smartphones (portrait) ----------- */
    @media only screen and (max-width : 320px) {
    /* Styles */
    .form-container {
        width: 100%;
      }
      .form-wrapper {
        width: 100%;
    }

    }
    
    /* iPads (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    /* Styles */
    }
    
    /* iPads (landscape) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    /* Styles */
    }
    
    /* iPads (portrait) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    /* Styles */
    }
    /**********
    iPad 3
    **********/
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    }
    
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    .form-container {
        width: 100%;
      }
      .form-wrapper {
        width: 100%;
    }

    }
    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 1224px) {
    /* Styles */
    }
    
    /* Large screens ----------- */
    @media only screen  and (min-width : 1824px) {
    /* Styles */
    }
    
    /* iPhone 4 ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    }
    
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    }
    
    /* iPhone 5 ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    .form-container {
        width: 100%;
      }
      .form-wrapper {
        width: 100%;
    }

    }
    
    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    /* iPhone X ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    /* Styles */

    }
    
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    .form-container {
        width: 100%;
      }
      .form-wrapper {
        width: 100%;
    }

    }
    
    /* iPhone XS Max, XR ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    /* Samsung Galaxy S3 ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    /* Samsung Galaxy S4 ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    /* Samsung Galaxy S5 ----------- */
    @media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }